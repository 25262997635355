<template>
  <v-row class="px-3">
    <v-col cols="12" md="10">
      <v-card v-for="(transfer, idx) in transfers" :key="idx" class="pa-5 my-3">
        <h3 class="primary--text text-subtitle-2">Transferência</h3>
        <v-radio-group mandatory v-model="transfer.type" :column="false" dense>
          <v-radio label="Transferência Bancária" :value="'bank'" />
          <v-radio class="ml-8" label="PIX" :value="'pix'" />
        </v-radio-group>

        <!----------------------------------
          Renderizado caso a forma de
          pagamento seja BANCO
        ------------------------------------>
        <v-row v-if="transfer.type === 'bank'" dense>
          <v-col cols="12" md="4">
            <v-text-field v-model="transfer.bank" label="Banco" placeholder="Banco" rounded outlined dense hide-details />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="transfer.agency" label="AG" placeholder="Agência" rounded outlined dense
              hide-details />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="transfer.account" label="CC" placeholder="Conta" rounded outlined dense hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="transfer.cpf_cnpj" label="CPF/CNPJ" placeholder="CPF/CNPJ" rounded outlined dense
              hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="transfer.favored" label="Favorecido" placeholder="Nome do favorecido" rounded outlined
              dense hide-details />
          </v-col>
        </v-row>

        <!----------------------------------
          Renderizado caso a forma de
          pagamento seja PIX
        ------------------------------------>
        <v-row v-else dense>
          <v-col cols="12" md="6">
            <v-text-field v-model="transfer.pix_key" label="Chave Pix" rounded outlined dense hide-details />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="transfer.pix_favored" label="Favorecido" placeholder="Nome do favorecido" rounded
              outlined dense hide-details />
          </v-col>
        </v-row>
        <v-btn v-if="transfers.length > 1" @click="$emit('removeTransfer', transfer)" v-text="'Remover Transferência'"
          rounded outlined class="my-3" color="primary" />
      </v-card>
      <v-btn @click="$emit('addTransfer')" v-text="'Adicionar Transferência'" rounded class="my-3 primary" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transfers: Array
  },
};
</script>